.cropper-result-block
    display: flex
    flex-direction: column
    align-items: center

    &__caption
        font-size: 18px
        margin: 0
        margin-top: 10px
        text-align: center
        color #eee

    &__image
        width: 100%
        height: 100%
        img
            width: 100%
            height: 100%!important
            display: block
            object-fit: cover
