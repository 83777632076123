.gallery-edit
    display: flex
    flex-wrap: wrap

    &__section
        margin-right: 20px
        margin-bottom: 25px

    &__image
        width: 130px
        height: 90px
        margin-bottom: 10px
        overflow: hidden

        img
            width: 100%
            height: 100%!important
            object-fit: cover

    &__buttons
        display: flex
        justify-content: center
        align-items: center
        // margin-left: -10px
        // margin-right: -10px
        button.btn
            margin: 0 5px
            font-size: 16px
            font-weight: bold
            &:first-child
                margin-left: 0
            &:last-child
                margin-right: 0

        .btn-primary
            background-color: #0d6efd
            border-color: #0d6efd
            &:hover
                background-color: #0a58ca
                border-color: #0a58ca
