.scroll-table-wrapper
    margin-bottom: 20px
    overflow-x: auto
    scrollbar-color: black #eee
    scrollbar-width: thin

    &_drag
        cursor: grabbing

    &::-webkit-scrollbar
        background: #eee
        height: 10px
    &::-webkit-scrollbar-thumb
        background: black

    & > .table
        min-width: 935px
