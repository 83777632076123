.fullscreen-loader
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    z-index: 10000
    background-color: rgba(0,0,0,.8)
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    visibility: hidden
    display: none

    &__content
        padding: 10px
        max-width: 500px
        width: 100%

    &_photos
        .fullscreen-loader
            &__content
                animation: loader-photos-blink 1.2s ease-in-out 0s infinite
                p
                    padding: 0
                    margin: 0
                    font-size: 30px
                    text-align: center
                    color: white
                    user-select: none

    &_shown
        visibility: visible
        display: flex

@keyframes loader-photos-blink
    0%
        opacity: 1
    50%
        opacity: 0.3
    100%
        opacity: 1
