input[type=checkbox]
    width: 25px
    height: 25px
    &[name=remember]
        width: 15px
        height: 15px
        position: relative
        top: 2px

.no-paddings
    padding: 0!important

.fixed-width-80
    width: 80px
    flex-shrink: 0
    margin-left: auto
.fixed-width-120
    width: 130px
    flex-shrink: 0
    margin-left: auto

.edit-news-section
    img
        max-width: 100%
        height: auto
