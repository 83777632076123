.cropper-block
    .cropper
        margin-bottom: 25px

.cropper-ibox
    .ibox-content
        padding: 0

.cropper
    margin: 15px 0
    max-height: 450px
    +under(1100)
        max-height: none

    &__wrapper
        display: flex
        +under(1100)
            flex-direction: column

    &__action
        flex: none
        display: flex
        flex-direction: column
        margin-right: 10px
        +under(1100)
            margin-right: 0
            margin-bottom: 10px

        .btn + .btn
            margin-top: 5px

    &__tools
        display: flex
        flex-direction: column
        margin-top: auto
        +under(1100)
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            flex-direction: row
            margin-bottom: 20px
        & > label,
        & > button
            +under(1100)
                width: (calc(50% - 10px))

    .aspect-ratio
        display: flex
        flex-direction: column
        +under(1100)
            margin-bottom: 20px
            flex-wrap: wrap
            justify-content: space-between
            flex-direction: row
            button
                width: (calc(50% - 10px))
                margin: 0
                margin-bottom: 10px

    .img-container
        width: calc(50% - 5px)
        min-height: 400px
        max-height: 400px
        display: flex
        align-items: center
        margin-right: 10px
        +under(1100)
            width: 100%
            margin-right: 0
            margin-bottom: 10px
        +under(500)
            min-height: 300px
            max-height: 300px
        +under(400)
            min-height: 250px
            max-height: 250px

    .docs-preview
        background-color: rgba(gray, .5)
        width: calc(50% - 5px)
        min-height: 400px
        max-height: 400px
        overflow: hidden
        display: flex
        align-items: center
        justify-content: center
        +under(1100)
            width: 100%
        +under(500)
            min-height: 300px
            max-height: 300px
        +under(400)
            min-height: 250px
            max-height: 250px

    img
        max-width: 100%

    .live-img
        opacity: 0
        visibility: hidden
        pointer-events: none
        height: 0
