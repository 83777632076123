.all-news-filter
    margin-bottom: 30px
    &__top
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 20px
        +under(1000)
            flex-direction: column

        & > select
            width: calc(50% - 20px)
            +under(1000)
                width: 100%
                margin-bottom: 20px
                &:last-child
                    margin-bottom: 0

    &__middle
        display: flex
        margin-bottom: 15px
        padding-left: 15px
        +under(1000)
            flex-direction: column

        & > label
            margin-right: 50px
            display: flex
            align-items: center
            +under(1000)
                margin-right: 0
                margin-bottom: 20px
            &:last-child
                margin-right: 0
                +under(1000)
                    margin-bottom: 0

            input
                transform: translate(5px, 0)
