.add-tag-button.btn
    // margin-left: 20px
    max-height: 30px
    height: 26px
    padding: 0
    display: inline-flex
    align-items: center
    justify-content: center
    width: 110px

.add-tag-modal
    position: fixed
    z-index: 1000
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-color: rgba(0,0, 0, .75)
    display: flex
    align-items: center
    justify-content: center

    &__wrapper
        display: flex
        flex-direction: column
        width: 320px
        max-width: 90vw
        background-color: #fff
        padding: 30px

    &__input
        margin-bottom: 30px

    &__buttons
        display: flex
        justify-content: space-between
        width: 100%

        .btn
            width: calc(50% - 20px)
