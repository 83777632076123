.cropper-results
    width: 100%
    &__title
        text-align: center
        margin-bottom: 25px
        font-size: 24px
    &__content
        display: flex
        width: 100%
        justify-content: center
        padding-left: 100px
        padding-right: 100px
        +under(1400)
            padding-left: 30px
            padding-right: 30px
        +under(1200)
            padding-left: 10px
            padding-right: 10px
        +under(600)
            flex-direction: column

    .cropper-result-block
        width: 45%
        margin: 0 20px
        +under(1300)
            width: 65%
        +under(600)
            width: 80%
            margin: 0 auto 40px

        &_small
            width: 25.35%
            +under(1300)
                width: 36.35%
            +under(600)
                width: 80%
                margin: 0 auto
