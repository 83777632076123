.aspect-ratio
    position: relative
    overflow: hidden
    width: 100%
    &__content
        position: absolute
        width: 100%
        height: 100%
        top: 0
        bottom: 0
        left: 0
        right: 0
