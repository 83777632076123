.ibox
    &-title
        +under(960)
            display: flex
            flex-direction: column
            h5
                display: flex
                margin-bottom: 10px
            .ibox-tools
                display: flex
                position: static
                margin: 0

                & > a
                    margin-left: 0
                    margin-right: 5px
                    &:last-child
                        margin-right: 0
